const CENTER_FLEX_CLASS = 'items-center justify-center content-center'

const placeholderSchema = {
  componentName: 'div',
  sectionPlaceholder: true,
  props: {
    className: `flex ${CENTER_FLEX_CLASS} w-full h-full min-h-[150px] rounded-lg bg-gray-200 p-1`,
    style: { pointerEvents: 'none' },
  },
  iconName: 'rectangle',
  labelName: '<Div> Container',
  componentType: 'container',
  children: [
    {
      componentName: 'div',
      props: {
        className: 'text-center',
      },
      iconName: 'rectangle',
      labelName: '<Div> Container',
      componentType: 'container',
      children: [
        {
          componentName: 'Icon',
          iconName: 'insert_emoticon',
          labelName: 'Material Icon',
          componentType: 'node',
          props: {
            className: 'text-gray-400',
            content: 'photo',
            fontSize: 'large',
            style: {
              pointerEvents: 'none',
            },
          },
        },
        {
          componentName: 'span',
          iconName: 'title',
          labelName: 'Span',
          componentType: 'node',
          props: {
            className: 'text-gray-400 text-weight-bold',
            content: `Component Placeholder`,
            style: { pointerEvents: 'none' },
          },
        },
      ],
    },
  ],
}

export const getSectionSchemaList = () => {
  const sectionSchemaNameList = SectionSchemaMap.map((sectionSchema) => ({
    name: sectionSchema.sectionName,
    icon: sectionSchema.iconName || 'add',
    iconStyle: sectionSchema.iconStyle || {},
  }))
  return sectionSchemaNameList
}

export const getSectionSchemaByName = (sectionName: string) => {
  const findSection = SectionSchemaMap.find(
    (schema) => schema.sectionName === sectionName
  )
  if (!findSection) {
    return SectionSchemaMap[0]
  }
  return findSection
}

export const SectionSchemaMap: any = [
  // 1 full row
  {
    componentName: 'div',
    sectionName: 'oneFullRow',
    iconName: 'calendar_view_day',
    props: {
      className: 'flex items-center bg-white w-full p-4',
    },
    children: [placeholderSchema],
  },
  // split 50:50
  {
    componentName: 'div',
    sectionName: 'splitHalf',
    iconName: 'splitscreen',
    iconStyle: {
      transform: 'rotate(90deg)',
    },
    props: {
      className: 'grid grid-flow-col-dense gap-2 p-4',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'flex flex-row items-center w-full row-span-3',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'flex flex-row items-center w-full row-span-3',
        },
        children: [placeholderSchema],
      },
    ],
  },
  // split 33:33:33
  {
    componentName: 'div',
    sectionName: 'splitThird',
    iconName: 'view_column',
    props: {
      className: 'grid grid-flow-col-dense gap-2 p-4',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
    ],
  },
  // split 25:25:25:25
  {
    componentName: 'div',
    sectionName: 'splitQuarter',
    iconName: 'calendar_view_week',
    props: {
      className: 'grid grid-flow-col-dense gap-2 p-4',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },

      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },

      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
    ],
  },
  // split 50: [50:50]
  {
    componentName: 'div',
    sectionName: 'splitHalfRightHalf',
    iconName: 'space_dashboard',
    props: {
      className: 'grid grid-flow-col-dense gap-2 p-4',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'col-span-1',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-2 col-span-1',
        },
        children: [placeholderSchema],
      },
    ],
  },
  // split [50:50]: 50
  {
    componentName: 'div',
    sectionName: 'splitHalfLeftHalf',
    iconName: 'space_dashboard',
    iconStyle: {
      transform: 'rotate(180deg)',
    },
    props: {
      className: 'grid grid-flow-col-dense gap-2 p-4',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'col-span-1',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-2 col-span-1',
        },
        children: [placeholderSchema],
      },
      {
        componentName: 'div',
        props: {
          className: 'row-span-3',
        },
        children: [placeholderSchema],
      },
    ],
  },

  //   split [1:4] [1:4] [1:4]
  {
    componentName: 'div',
    sectionName: 'splitOneFourthTimesThree',
    iconName: 'list',

    props: {
      className: '',
    },
    children: [
      {
        componentName: 'div',
        props: {
          className: 'grid grid-flow-col-dense gap-2 px-4 py-1',
        },
        children: [
          {
            componentName: 'div',
            props: {
              className: 'row-span-1',
            },
            children: [placeholderSchema],
          },
          {
            componentName: 'div',
            props: {
              className: 'row-span-1 col-span-3',
            },
            children: [placeholderSchema],
          },
        ],
      },
      {
        componentName: 'div',
        props: {
          className: 'grid grid-flow-col-dense gap-2 px-4 py-1',
        },
        children: [
          {
            componentName: 'div',
            props: {
              className: 'row-span-1',
            },
            children: [placeholderSchema],
          },
          {
            componentName: 'div',
            props: {
              className: 'row-span-1 col-span-3',
            },
            children: [placeholderSchema],
          },
        ],
      },
      {
        componentName: 'div',
        props: {
          className: 'grid grid-flow-col-dense gap-2 px-4 py-1',
        },
        children: [
          {
            componentName: 'div',
            props: {
              className: 'row-span-1',
            },
            children: [placeholderSchema],
          },
          {
            componentName: 'div',
            props: {
              className: 'row-span-1 col-span-3',
            },
            children: [placeholderSchema],
          },
        ],
      },
    ],
  },
]
