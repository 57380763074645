// DOESN'T WORK, REWORK THIS
import React from 'react'

import styles from './imageResponsive.module.css'
// image utils because conditional render Next/Image causing error

interface ImageProps extends React.HTMLProps<ImageProps> {
  src: any
  width?: number
  height?: number
  alt?: string
  fill?: boolean
  containerClassName?: string
}

export const ImageWrapper = (props: ImageProps) => {
  const {
    src,
    width,
    height,
    alt = 'cartool-image',
    className,
    ...imageProps
  } = props
  const isResponsive =
    !width && !height
      ? { layout: 'fill', objectFit: 'contain' }
      : { width, height }

  try {
    // @ts-ignore
    return React.createElement(require.resolve('next/image').default, {
      src: typeof src === 'string' ? src : src.src,
      ...isResponsive,
      alt,
      className: `${styles.image} ${className}`,
      ...imageProps,
    })
  } catch {
    return React.createElement('img', {
      src,
      width,
      height,
      alt,
      className,
      ...imageProps,
    })
  }
}

export const Image = (props: ImageProps) => {
  const {
    src,
    width = 0,
    height = 0,
    alt = 'cartool-image',
    className,
    containerClassName,
    ...imageProps
  } = props

  const isResponsive =
    !width && !height
      ? { layout: 'fill', objectFit: 'contain' }
      : { width, height }

  return (
    <div className={`${styles['image-container']} ${containerClassName}`}>
      <ImageWrapper
        src={src}
        alt={alt}
        {...isResponsive}
        className={`${styles.image} ${className}`}
        {...imageProps}
      />
    </div>
  )
}
