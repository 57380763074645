import React from 'react'

import { Button, Icon } from 'components/@wartek'

import {
  getSectionSchemaByName,
  getSectionSchemaList,
} from '../presets/section'

interface SectionPopoverContentProps {
  onSectionSelect: (section: any) => void
}

export const SectionPopoverContent = (props: SectionPopoverContentProps) => {
  const { onSectionSelect } = props
  const sectionList = getSectionSchemaList()

  const handleSelectSection = (sectionName: string) => {
    const sectionSchema = getSectionSchemaByName(sectionName)
    onSectionSelect(sectionSchema)
  }

  return (
    <div className="flex flex-col p-4">
      <span className="font-weight mb-4">Select Layout</span>
      <div className="flex flex-row">
        {sectionList.map((section) => (
          <Button
            color="white"
            iconOnly
            className="mr-2"
            data-tooltip={section.name}
            key={section.name}
            onClick={() => handleSelectSection(section.name)}
          >
            <Icon style={section.iconStyle} color="inherit">
              {section.icon}
            </Icon>
          </Button>
        ))}
      </div>
    </div>
  )
}
