import * as React from 'react'
import {
  ComponentRendererPropsType,
  JSONSchemaRendererType,
} from 'types/web-builder'

import {
  filterEmptyObjectFromArray,
  getSelectedComponentByName,
} from './SchemaUtils'

const RenderComponent = (props: ComponentRendererPropsType) => {
  const { data, nodeConfig } = props
  const componentDataList = data
  if (componentDataList.length === 0) {
    return null
  }

  return componentDataList.map((componentNodeData: JSONSchemaRendererType) => {
    const SelectedComponent = getSelectedComponentByName(
      componentNodeData.componentName
    )
    const props = componentNodeData?.props || {}
    const childrenNode = componentNodeData?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)

    let overrideComponentData = { props, children: childNodes }
    const eventListenerId =
      componentNodeData?.eventListenerId || componentNodeData?.props?.id || ''
    if (eventListenerId && nodeConfig && nodeConfig[eventListenerId]) {
      const componentDataChildNodes = Array.isArray(childNodes)
        ? childNodes
        : []
      overrideComponentData = {
        props: {
          ...props,
          ...nodeConfig[eventListenerId].props,
        },
        children: [
          ...componentDataChildNodes,
          ...(nodeConfig[eventListenerId].children || []),
        ],
      }
    }

    return (
      // @ts-ignore <- bypass err construct call signature of jsx
      <SelectedComponent
        key={`${componentNodeData.componentName}-${componentNodeData.componentId}`}
        {...overrideComponentData.props}
      >
        {overrideComponentData.children &&
          overrideComponentData.children.map(
            (child: JSONSchemaRendererType) => {
              return RenderComponent({
                data: [child],
                nodeConfig,
              })
            }
          )}
      </SelectedComponent>
    )
  })
}

const MemoizedRenderer = React.memo((props: ComponentRendererPropsType) => (
  // @ts-ignore <- bypass default renderer type (because we do custom render / pointer)
  <RenderComponent data={props.data} nodeConfig={props.nodeConfig} />
))

export const ComponentRenderer = (props: ComponentRendererPropsType) => {
  const supportedNodeEventMap = ['onClick', 'onMouseEnter', 'onMouseLeave']
  const { data, nodeConfig = null } = props
  const nodeFunctionMap = {
    changeNode: (eventId: string, dataKey: string, dataValue: string) => {
      try {
        const parsedValue = JSON.parse(dataValue)
        setNodeState((prevState) => ({
          ...prevState,
          [eventId]: {
            ...prevState[eventId],
            [dataKey]: parsedValue,
          },
        }))
      } catch (e) {
        console.error(e)
      }
    },
  }

  const parseEventStringToFunction = (eventString: string) => {
    try {
      const functionName = eventString.match(/([a-z0-9]+)/gi)[0]
      const functionArgs = eventString.match(/\(([^)]+)\)/)[1]
      const parsedArgs = functionArgs.split(',').map((arg) => arg.trim())

      const targetEventId = parsedArgs[0]
      const targetDataKey = parsedArgs[1]
      const targetDataValue = parsedArgs[2]
      return () => {
        nodeFunctionMap[functionName](
          targetEventId,
          targetDataKey,
          targetDataValue
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  const generateNodeConfigEventFn = () => {
    if (nodeConfig) {
      return Object.keys(nodeConfig).reduce((acc, eventId) => {
        // change this to support multiple event listener
        const eventListenerKey = supportedNodeEventMap.find((event) => {
          return nodeConfig[eventId].props[event]
        })
        const eventListenerFn =
          eventListenerKey && nodeConfig[eventId].props[eventListenerKey]

        if (eventListenerFn) {
          try {
            const parsedFn = parseEventStringToFunction(eventListenerFn)

            return {
              ...acc,
              [eventId]: {
                ...nodeConfig[eventId],
                props: {
                  ...nodeConfig[eventId].props,
                  [eventListenerKey]: parsedFn,
                },
              },
            }
          } catch (e) {
            console.error(e)
          }
        }

        return acc
      }, {})
    }
    return null
  }

  const [nodeState, setNodeState] = React.useState(generateNodeConfigEventFn())

  // @ts-ignore TODO fix this later
  return <MemoizedRenderer data={data} nodeConfig={nodeState} />
}
