export const getSchemaLegendByName = (schemaName: string) => {
  return (
    schemaLegendMap[schemaName] || {
      iconName: 'code_block',
      labelName: 'Custom Component',
      componentType: 'container',
    }
  )
}

export const schemaLegendMap = {
  div: {
    iconName: 'fullscreen',
    labelName: 'Div Container',
    componentType: 'container',
  },
  Calendar: {
    iconName: 'calendar_month',
    labelName: 'Calendar',
    componentType: 'node',
  },
  Card: {
    iconName: 'font_download',
    labelName: 'Card Container',
    componentType: 'container',
  },
  ImageHTML: {
    iconName: 'image',
    labelName: 'Image',
    componentType: 'node',
  },
  Image: {
    iconName: 'image',
    labelName: 'Image',
    componentType: 'node',
  },
  Avatar: {
    iconName: 'account_circle',
    labelName: 'User Avatar',
    componentType: 'node',
  },
  Badge: {
    iconName: 'toggle_on',
    labelName: 'Pill Badge',
    componentType: 'node',
  },
  Link: {
    iconName: 'link',
    labelName: 'URL Container',
    componentType: 'container',
  },
  List: {
    iconName: 'view_list',
    labelName: 'List Container',
    componentType: 'container',
  },
  ListItem: {
    iconName: 'list',
    labelName: 'List Item',
    componentType: 'node',
  },
  ListItemAsContainer: {
    iconName: 'list',
    labelName: 'List Item Container',
    componentType: 'container',
  },
  Text: {
    iconName: 'title',
    labelName: 'Text',
    componentType: 'node',
  },
  span: {
    iconName: 'title',
    labelName: 'Inline Text',
    componentType: 'node',
  },
  Separator: {
    iconName: 'horizontal_rule',
    labelName: 'Line Separator',
    componentType: 'node',
  },
  Button: {
    iconName: 'crop_16_9',
    labelName: 'Button',
    componentType: 'node',
  },
  Icon: {
    iconName: 'insert_emoticon',
    labelName: 'Material Icon',
    componentType: 'node',
  },
  Banner: {
    iconName: 'campaign',
    labelName: 'Closable Banner',
    componentType: 'node',
  },
  ProgressBar: {
    iconName: 'percent',
    labelName: 'Progress Bar',
    componentType: 'node',
  },
  Carousel: {
    iconName: 'view_carousel',
    labelName: 'Slider Carousel Container',
    componentType: 'container',
  },
  Form: {
    iconName: 'edit_note',
    labelName: 'Form Container',
    componentType: 'container',
  },
  Input: {
    iconName: 'text_fields',
    labelName: 'Text Input',
    componentType: 'node',
  },
  RadioGroup: {
    iconName: 'radio_button_checked',
    labelName: 'Radio Group Container',
    componentType: 'container',
  },
  Radio: {
    iconName: 'radio_button_checked',
    labelName: 'Radio Input',
    componentType: 'node',
  },
  Popover: {
    iconName: 'difference',
    labelName: 'Popover Container',
    componentType: 'container',
  },
  Expander: {
    iconName: 'expand_more',
    labelName: 'Expander Container',
    componentType: 'container',
  },
  Collapsible: {
    iconName: 'unfold_more',
    labelName: 'Collapsible Container',
    componentType: 'container',
  },
  ButtonDropdownMenu: {
    iconName: 'difference',
    labelName: 'Button Dropdown Menu',
    componentType: 'node',
  },
  hrefContainer: {
    iconName: 'link',
    labelName: '<a> Node </a>',
    componentType: 'container',
  },
}
