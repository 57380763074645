// TODO: UNTESTED! Test this alongside with web builder page implementation
// TODO: rework Container highlight, the node hightlight seems ok
import * as React from 'react'
import {
  ComponentRendererPropsType,
  JSONSchemaRendererType,
  SelectedNodeEventType,
} from 'types/web-builder'

import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/@wartek'
import { getSchemaLegendByName } from 'components/ComponentBuilder/SchemaLegend'

import { SectionPopoverContent } from './blocks/SectionPopoverContent'
import {
  filterEmptyObjectFromArray,
  getSelectedComponentByName,
} from './SchemaUtils'

// TODO: REWORK THIS ACCORDING NEEDS LATER
interface CanvasRendererPropsType extends ComponentRendererPropsType {
  highlightedId?: string | null
  deleteSelectedNode?: (data?: JSONSchemaRendererType) => void
  moveNodeOrder?: (data: any) => void
  triggerDoubleClick?: (data: any) => void
  // newprops
  onNodeClick: (data: SelectedNodeEventType) => void
  nodeIndex?: number[]
  selectedNodeId?: string | null
  onAddSection: (data: SelectedNodeEventType) => void
  disabledAddSection?: boolean

  onHightlightNodeAddClick: (nodeIndex: number[], e: any) => void
  onHightlightNodeDeleteClick: (nodeIndex: number[], e: any) => void
  onHightlightContainerAddClick: (nodeIndex: number[], e: any) => void
  onHightlightContainerDeleteClick: (nodeIndex: number[], e: any) => void
}

type CanvasContainerHighlighterPropsType = {
  nodeIndex: number[]
  onHightlightContainerAddClick: (nodeIndex: number[], e: any) => void
  onHightlightContainerDeleteClick: (nodeIndex: number[], e: any) => void
  deleteSelectedNode?: CanvasRendererPropsType['deleteSelectedNode']
  moveNodeOrder?: CanvasRendererPropsType['moveNodeOrder']
  componentNodeData: JSONSchemaRendererType
  isDisableMoveNodeDown?: boolean
  isDisableMoveNodeUp?: boolean
  isHighlighted: boolean
  children?: React.ReactNode
}

type CanvasNodeHightlightWrapperPropsTypes = {
  nodeIndex?: number[]
  onHightlightNodeAddClick?: (nodeIndex: number[], e: any) => void
  onHightlightNodeDeleteClick?: (nodeIndex: number[], e: any) => void
  componentNodeData: JSONSchemaRendererType
  isHighlighted: boolean
  children?: React.ReactNode
}

type HighlightCanvasWrapperPropsType = {
  nodeIndex: number[]
  isHighlighted: boolean
  children?: React.ReactNode
  nodeHightlightProps: any
  onHightlightNodeAddClick: (nodeIndex: number[], e: any) => void
  onHightlightNodeDeleteClick: (nodeIndex: number[], e: any) => void
}

const DEFAULT_OUTLINE_CLASS =
  'cursor-pointer hover:relative hover:outline hover:z-20 hover:outline-violet-300 outline-offset-[-1px] outline-violet-500'

const CanvasContainerHighlighter = (
  props: CanvasContainerHighlighterPropsType
) => {
  const { componentNodeData, isHighlighted } = props
  return (
    <div
      className={`absolute top-[-28px] z-30 group-hover:visible  ${
        isHighlighted ? 'visible' : 'invisible'
      }`}
    >
      <span
        className=" bg-blue-500 p-2 text-xs text-white"
        style={{ fontWeight: 'bold', fontSize: '10px' }}
      >
        {/* @ts-ignore */}
        <Icon color="inherit" fontSize="sm" className="mr-2">
          {getSchemaLegendByName(componentNodeData.componentName).iconName}
        </Icon>
        {getSchemaLegendByName(componentNodeData.componentName).labelName}
      </span>
    </div>
  )
}

const CanvasNodeHightlightWrapper = (
  props: CanvasNodeHightlightWrapperPropsTypes
) => {
  const { children, componentNodeData, isHighlighted } = props
  return (
    <div className="relative">
      {isHighlighted && (
        <div
          className={`${
            isHighlighted ? 'visible' : 'invisible'
          } absolute top-[-32px] z-30 flex rounded`}
        >
          <span
            className="max-h-[100px]  truncate bg-green-500 p-2 text-white"
            style={{ fontWeight: 'bold', fontSize: '10px' }}
          >
            {/* @ts-ignore */}
            <Icon color="inherit" fontSize="sm" className="mr-2">
              {getSchemaLegendByName(componentNodeData.componentName).iconName}
            </Icon>
            {getSchemaLegendByName(componentNodeData.componentName).labelName}
          </span>
        </div>
      )}
      {children}
    </div>
  )
}

const HighlightCanvasWrapper = (props: HighlightCanvasWrapperPropsType) => {
  const {
    isHighlighted,
    nodeHightlightProps,
    children,
    nodeIndex,
    onHightlightNodeAddClick,
    onHightlightNodeDeleteClick,
  } = props
  const isSingleNode = !nodeHightlightProps.componentNodeData?.children
  if (isSingleNode) {
    return (
      <CanvasNodeHightlightWrapper
        {...nodeHightlightProps}
        isHighlighted={isHighlighted}
        onHightlightNodeAddClick={onHightlightNodeAddClick}
        onHightlightNodeDeleteClick={onHightlightNodeDeleteClick}
        nodeIndex={nodeIndex}
      >
        {children}
      </CanvasNodeHightlightWrapper>
    )
  }

  return <>{children}</>
}

const RenderCanvas = (props: CanvasRendererPropsType) => {
  const {
    data,
    highlightedId,
    nodeConfig,
    deleteSelectedNode,
    moveNodeOrder,
    triggerDoubleClick,
    onNodeClick,
    nodeIndex,
    onAddSection,
    disabledAddSection,
    onHightlightNodeAddClick,
    onHightlightNodeDeleteClick,
    onHightlightContainerAddClick,
    onHightlightContainerDeleteClick,
  } = props
  const componentDataList = data
  if (!componentDataList || componentDataList.length === 0) {
    return null
  }

  return componentDataList.map((componentNodeData: JSONSchemaRendererType) => {
    const SelectedComponent = getSelectedComponentByName(
      componentNodeData.componentName
    )
    const props = componentNodeData?.props || {}
    const childrenNode = componentNodeData?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)

    const componentClassName = componentNodeData?.props?.className || ''
    const componentId = componentNodeData?.componentId || ''

    // TODO: Rework This Node Move Method
    const isHighlighted = componentId === highlightedId
    const isContainer =
      componentNodeData?.children || componentNodeData.componentName === 'div'
    const isContainerAndHighlighted = isContainer && isHighlighted
    const isNodeAndHighlighted = !isContainer && isHighlighted

    // Section container  - first child of the tree
    // for user we need to make this easy enough to add new section
    const isSectionContainer = nodeIndex?.length === 2

    // temporary disabled, because it alter absolute component position when scrolling
    // if (isHighlighted) {
    //   const highlightedEl = document.querySelector(
    //     `[data-renderedId="${componentId}"]`
    //   );

    //   highlightedEl?.scrollIntoView({
    //     behavior: "smooth",
    //     // block: "center",
    //     // inline: "center",
    //   });
    // }

    return (
      <HighlightCanvasWrapper
        key={`${componentNodeData.componentName}-${componentId}`}
        isHighlighted={isNodeAndHighlighted}
        nodeHightlightProps={{
          componentNodeData,
          isHighlighted: isNodeAndHighlighted,
        }}
        nodeIndex={nodeIndex}
        onHightlightNodeAddClick={onHightlightNodeAddClick}
        onHightlightNodeDeleteClick={onHightlightNodeDeleteClick}
      >
        {/* @ts-ignore <- bypass err construct call signature of jsx */}
        <SelectedComponent
          {...props}
          data-renderedId={componentId}
          onClick={(e: Event) => {
            e.stopPropagation()
            e.preventDefault()
            triggerDoubleClick && triggerDoubleClick(false)
            onNodeClick({
              selectedNode: componentNodeData,
              index: nodeIndex || [0],
            })
          }}
          onDoubleClick={(e: Event) => {
            e.stopPropagation()
            e.preventDefault()
            triggerDoubleClick && triggerDoubleClick(true)
          }}
          className={`${componentClassName} ${
            isHighlighted && 'relative z-20 outline-dashed'
          } ${DEFAULT_OUTLINE_CLASS}`}
        >
          {isContainer && isHighlighted && (
            <CanvasContainerHighlighter
              componentNodeData={componentNodeData}
              isHighlighted={isContainerAndHighlighted}
              nodeIndex={nodeIndex}
              onHightlightContainerAddClick={onHightlightContainerAddClick}
              onHightlightContainerDeleteClick={
                onHightlightContainerDeleteClick
              }
            />
          )}
          {childNodes &&
            childNodes.map((child: JSONSchemaRendererType, index: number) => {
              return RenderCanvas({
                data: [child],
                nodeConfig,
                highlightedId,
                deleteSelectedNode,
                moveNodeOrder,
                triggerDoubleClick,
                onNodeClick,
                nodeIndex: [...(nodeIndex || [0]), index],
                onAddSection,
                disabledAddSection,
                onHightlightNodeAddClick,
                onHightlightNodeDeleteClick,
                onHightlightContainerAddClick,
                onHightlightContainerDeleteClick,
              })
            })}
        </SelectedComponent>
        {!disabledAddSection && isSectionContainer && (
          <div className={`relative z-50 `}>
            <div className="absolute bottom-0 top-[-10px] flex w-full justify-center">
              <Popover>
                <PopoverTrigger>
                  <Button
                    iconOnly
                    color="white"
                    className="opacity-20 hover:opacity-100"
                    // @ts-ignore
                    size="small"
                  >
                    <Icon fontSize="small">add</Icon>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <SectionPopoverContent
                    onSectionSelect={(sectionData) => {
                      onAddSection({
                        index: nodeIndex || [0],
                        selectedNode: sectionData,
                      })
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
        )}
      </HighlightCanvasWrapper>
    )
  })
}

const MemoizedCanvasRenderer = React.memo((props: CanvasRendererPropsType) => (
  // @ts-ignore <- bypass default renderer type (because we do custom render / pointer)
  <RenderCanvas {...props} />
))

export const CanvasRenderer = (props: CanvasRendererPropsType) => {
  const {
    data,
    nodeConfig,
    deleteSelectedNode = () => null,
    moveNodeOrder = () => null,
    triggerDoubleClick = () => null,
    onNodeClick = () => null,
    selectedNodeId = null,
    onAddSection = () => null,
    disabledAddSection = false,
    onHightlightNodeAddClick,
    onHightlightNodeDeleteClick,
    onHightlightContainerAddClick,
    onHightlightContainerDeleteClick,
  } = props

  const [selectedId, setSelectedId] = React.useState<string | null>(
    selectedNodeId
  )
  const handleNodeClick = (data: SelectedNodeEventType) => {
    setSelectedId(data?.selectedNode?.componentId || null)
    onNodeClick(data)
  }

  React.useEffect(() => {
    setSelectedId(selectedNodeId)
  }, [selectedNodeId])

  return (
    <MemoizedCanvasRenderer
      data={data}
      nodeConfig={nodeConfig}
      highlightedId={selectedId}
      deleteSelectedNode={deleteSelectedNode}
      moveNodeOrder={moveNodeOrder}
      triggerDoubleClick={triggerDoubleClick}
      onNodeClick={handleNodeClick}
      nodeIndex={[0]}
      onAddSection={onAddSection}
      disabledAddSection={disabledAddSection}
      onHightlightNodeAddClick={onHightlightNodeAddClick}
      onHightlightNodeDeleteClick={onHightlightNodeDeleteClick}
      onHightlightContainerAddClick={onHightlightContainerAddClick}
      onHightlightContainerDeleteClick={onHightlightContainerDeleteClick}
    />
  )
}
