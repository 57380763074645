import { JSONSchemaRendererType } from 'types/web-builder'

import { UIComponentMap } from './ComponentMap'

const DefaultComponentMap = 'div'

export const getSelectedComponentByName = (componentName: string) => {
  const componentByName = componentName || DefaultComponentMap
  const getComponentMap = UIComponentMap

  if (getComponentMap[componentByName]) {
    return getComponentMap[componentByName]
  }

  return null
}

export const isObjectEmpty = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export const filterEmptyObjectFromArray = (
  arr: JSONSchemaRendererType['children']
) => {
  return (
    arr &&
    arr?.filter((obj) => {
      return !isObjectEmpty(obj)
    })
  )
}
