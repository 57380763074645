import React from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'

import { Button, Text } from 'components/@wartek'

import { FormComponentMap, formComponentMapToType } from './ComponentMap'

// TODO: adjust for more form type (date, file)

// TYPESCRIPT ANY
// eslint-disable-file

interface InputBuilderProps {
  data: any
  config?: any
  methods?: any
  configOverride?: boolean // override form schema using config
  defaultValue?: any
}

interface ComponentSelectorProps {
  data?: any
  config?: any
  methods?: any
  name?: string
  type?: string
  onChange?: (value: any) => void
  value?: string
  defaultValue?: any
  ref?: any
}

interface FormBuilderProps {
  data?: any
  config?: any
  onSubmitButtonClick?: (data: any) => void
  configOverride?: boolean // override form schema using config
  children?: React.ReactNode
  submitButtonClass?: string
  submitButtonText?: string
  submitButonProps?: any
  formClass?: string
  formMode?: 'onBlur' | 'onChange' | 'onSubmit'
}

const DefaultComponentMap = 'Input'

const getProperDataValueType = (data) => {
  const checkIfArray = (data) => Array.isArray(data)
  const checkIfNull = (data) => data === null
  const getProperDataType = (data) => {
    if (checkIfNull(data)) return 'string'
    if (checkIfArray(data)) return 'array'
    return typeof data
  }

  return getProperDataType(data)
}

const formToTypeMap = (data, isConfigData = false) => {
  const ignoreConfigKeys = ['excludeKeys']
  let schemaFormMap = []
  if (isConfigData) {
    schemaFormMap = Object.keys(data)
      .map((key) => {
        return {
          name: key,
          type: data[key].type,
        }
      })
      .filter((item) => {
        return ignoreConfigKeys.indexOf(item.name) === -1
      })
  } else {
    schemaFormMap = Object.keys(data).map((key) => {
      return {
        name: key,
        type: getProperDataValueType(data[key]),
      }
    })
  }

  return schemaFormMap
}

const getSelectedComponentByName = (componentName) => {
  const componentByName = componentName || DefaultComponentMap
  const getComponentList = FormComponentMap

  if (getComponentList[componentByName]) {
    return getComponentList[componentByName]
  }

  return <> </>
}

const ComponentSelector = React.memo((props: ComponentSelectorProps) => {
  const {
    name: formName,
    type,
    config = {},
    defaultValue: formDefaultValue,
  } = props
  const getComponentNameByType = formComponentMapToType[type] || 'Input'
  const componentName = config?.componentName || getComponentNameByType[0]
  const SelectedComponent = getSelectedComponentByName(componentName)
  const methods = useFormContext()

  const { control, formState } = methods

  const { errors } = formState
  const currentDefaultValue = config?.props?.defaultValue || formDefaultValue
  const valueObj = {
    defaultValue: currentDefaultValue,
  }

  const errorMessageAlert = {
    errorMessage: errors?.[formName]?.message,
  }

  return (
    <div className="mb-4 flex flex-col">
      <Text variant="helper-bold">{config.label || formName}</Text>

      <Controller
        control={control}
        name={formName}
        rules={{
          required: 'Wajib diisi',
          ...config.rules,
        }}
        defaultValue={currentDefaultValue}
        render={({ field: { onChange } }) => (
          // @ts-ignore
          <SelectedComponent
            key={formName}
            className={`my-2 ${config.className}`}
            {...errorMessageAlert}
            id={formName}
            name={formName}
            {...config.props}
            {...valueObj}
            onChange={(value) => onChange(value)}
          />
        )}
      />
      {componentName === 'Input' && (
        <Text color="critical" variant="helper">
          {/* @ts-ignore */}
          {errorMessageAlert?.errorMessage}
        </Text>
      )}
    </div>
  )
})

ComponentSelector.displayName = 'ComponentSelector'

export const InputBuilder = React.forwardRef(
  (props: InputBuilderProps, ref) => {
    const { data = {}, config, configOverride = false } = props

    const formTypeMap =
      (configOverride
        ? formToTypeMap(config, configOverride)
        : formToTypeMap(data)) || []

    const formConfig = config || {}
    const { excludeKeys = [] } = formConfig

    return (
      <>
        {formTypeMap.map(
          (formSchema) =>
            !excludeKeys.includes(formSchema.name) && (
              <ComponentSelector
                key={formSchema.name}
                {...formSchema}
                config={{
                  ...formConfig[formSchema.name],
                }}
                defaultValue={data[formSchema.name]}
                ref={ref}
              />
            )
        )}
      </>
    )
  }
)

InputBuilder.displayName = 'InputBuilder'

export const FormBuilder = (props: FormBuilderProps) => {
  const {
    data,
    config,
    configOverride = false,
    onSubmitButtonClick = () => ({}),
    submitButtonClass = '',
    submitButtonText = 'Submit',
    submitButonProps = {},
    formClass = '',
    formMode = 'onSubmit',
  } = props

  const methods = useForm({ mode: formMode })
  const { handleSubmit } = methods

  const onSubmit = (val) => {
    onSubmitButtonClick(val)
  }

  return (
    <FormProvider {...methods}>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <InputBuilder {...{ data, config, configOverride }} />

        {props.children}

        <Button
          type="submit"
          data-testid="formbuilder-submit-button"
          className={submitButtonClass}
          {...submitButonProps}
        >
          {submitButtonText}
        </Button>
      </form>
    </FormProvider>
  )
}

export default InputBuilder
export const useFormBuilderContext = useFormContext
