import React from 'react'
import GridLayout from 'react-grid-layout'

import { UIComponentMap } from './ComponentMap'
interface GridBuilderProps {
  // eslint-disable-next-line
  data: any
  config?: any
  builderConfig?: any
}

const DefaultComponentMap = 'div'

const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

const filterEmptyObjectFromArray = (arr) => {
  return arr.filter((obj) => {
    return !isObjectEmpty(obj)
  })
}

const getSelectedComponentByName = (componentName) => {
  const componentByName = componentName || DefaultComponentMap
  const getComponentList = UIComponentMap

  if (getComponentList[componentByName]) {
    return getComponentList[componentByName]
  }

  return <> </>
}

const RenderGridItemBuilder = (props: any) => {
  const { data, builderConfig } = props
  const { isPreviewMode, onNodeClick } = builderConfig
  if (data.length === 0) {
    return <></>
  }

  return data.map((gridItem) => {
    const SelectedComponent = getSelectedComponentByName(gridItem.componentName)
    const props = gridItem?.props || {}
    const componentId = gridItem?.componentId || ''
    const childrenNode = gridItem?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)

    return (
      <div
        key={componentId}
        className={`h-full w-full overflow-hidden`}
        onClick={(e) => {
          e.stopPropagation()
          onNodeClick && onNodeClick(gridItem)
        }}
      >
        {!isPreviewMode && (
          <div className="absolute h-full w-full cursor-grab border-4 border-dashed border-pink-400"></div>
        )}
        <div className="p-1">
          {/* @ts-ignore */}
          <SelectedComponent
            {...props}
            data-renderedid={componentId}
            onClick={(e) => {
              e.stopPropagation()
              onNodeClick && onNodeClick(gridItem)
            }}
          >
            {childNodes &&
              childNodes.map((child) => {
                return RenderGridItemBuilder({
                  data: [child],
                  onNodeClick,
                })
              })}
          </SelectedComponent>
        </div>
      </div>
    )
  })
}

const RenderGridItem = (props: any) => {
  const { data } = props
  if (data.length === 0) {
    return <></>
  }

  return data.map((gridItem) => {
    const SelectedComponent = getSelectedComponentByName(gridItem.componentName)
    const props = gridItem?.props || {}
    const componentId = gridItem?.componentId || ''
    const childrenNode = gridItem?.children || []
    const childNodes = filterEmptyObjectFromArray(childrenNode)

    return (
      <div key={componentId} className={`h-full w-full overflow-hidden`}>
        {/* @ts-ignore */}
        <SelectedComponent {...props} data-renderedid={componentId}>
          {childNodes &&
            childNodes.map((child) => {
              return RenderGridItem({
                data: [child],
              })
            })}
        </SelectedComponent>
      </div>
    )
  })
}

const DEFAULT_GRID_LAYOUT_CONFIG = {
  useCSSTransforms: false,
  compactType: 'vertical',
  cols: 12,
  isBounded: true,
  margin: [0, 0],
  rowHeight: 50,
  containerPadding: [0, 0],
  width: 1200,
  allowOverlap: true,
}

export const GridBuilder = (props: GridBuilderProps) => {
  const { data, config = null, builderConfig = null } = props
  const layoutConfig = { ...DEFAULT_GRID_LAYOUT_CONFIG, ...config }

  if (builderConfig)
    return (
      <GridLayout
        {...layoutConfig}
        style={{ minHeight: '500px', zIndex: 2 }}
        {...builderConfig}
      >
        {RenderGridItemBuilder({ data, builderConfig })}
      </GridLayout>
    )
  return (
    <GridLayout
      {...layoutConfig}
      style={{ minHeight: '500px', zIndex: 2 }}
      {...builderConfig}
    >
      {RenderGridItem({ data, builderConfig })}
    </GridLayout>
  )
}

export default GridBuilder
