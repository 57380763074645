import React from 'react'
import GridLayout from 'react-grid-layout'

import { PdfViewer } from '@wartek-id/pdf-viewer'

import { Image } from 'components'
import {
  Avatar,
  Badge,
  Banner,
  BannerDescription,
  Breadcrumb,
  Button,
  Card,
  Carousel,
  Collapsible,
  CollapsibleContent,
  CollapsibleIcon,
  CollapsibleTrigger,
  Dialog,
  DialogCancel,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  Expander,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  List,
  ListItem,
  Navbar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ProgressBar,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Separator,
  Shimmer,
  Spinner,
  Stepper,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
} from 'components/@wartek'

// unregistered component in library
import { Calendar } from './CustomSchemaComponent'

export type ComponentMap = {
  // eslint-disable-next-line
  [componentName: string]: React.ComponentType<any>
}

export const CompatibleComponentMapV2 = {
  div: {
    props: {
      className: 'p-4 h-full',
    },
    children: [],
  },
  Calendar: {
    props: {
      style: {
        height: '500px',
      },
    },
  },
  Card: {
    props: {
      className: 'p-4',
    },
    children: [],
  },
  ImageHTML: {
    props: {
      className: 'w-full',
      src: 'https://wallpaperaccess.com/full/32048.jpg',
      width: '300px',
      height: '300px',
    },
  },
  Image: {
    props: {
      src: 'https://wallpaperaccess.com/full/32048.jpg',
    },
  },
  Avatar: {
    props: {
      alt: 'Some Alt',
      shape: 'circle',
      className: '',
      size: 'md',
      src: 'https://avatars.githubusercontent.com/u/5605?s=48&v=4',
    },
    // propTypes: AvatarProps as any,
  },
  Badge: {
    props: {
      content: 'hehehehe',
      className: 'p-4',
      variant: '',
    },
  },
  Link: {
    props: {
      variant: 'primary',
      className: 'p',
      href: 'https://google.com',
    },
    children: [],
  },
  List: {
    props: {
      type: 'list',

      className: 'p-4',
    },
    children: [],
  },
  ListItem: {
    props: {
      content: 'List Item',
      className: 'p-4',
    },
  },
  Text: {
    props: {
      content: 'Text',
      className: 'p-4',
      variant: 'subheading',
    },
  },
  Separator: {
    props: {
      className: 'p-1',
    },
  },
  Button: {
    props: {
      content: 'Button',
      className: 'p-4',
      variant: 'primary',
      type: 'button',
    },
    children: [
      {
        component: 'Text',
        props: {
          content: 'Button',
          className: 'p-4',
          variant: 'subheading',
        },
      },
    ],
  },
  Icon: {
    props: {
      color: 'inherit',
      className: 'p-4',
      content: 'tune',
    },
  },
  Banner: {
    props: {
      className: 'p-4',
      content: 'Banner',
      variant: '',
    },
  },
  ProgressBar: {
    props: {
      className: 'p-4',
      variant: '',
    },
  },
  Carousel: {
    props: {},
    children: [],
  },
  // Tab: {
  //   props: {
  //     content: 'tab x',
  //   },
  // },
  // Tabs: {
  //   props: {},
  //   children: [],
  // },
  // TabList: {
  //   props: {},
  //   children: [],
  // },
  // TabPanels: {
  //   props: {},
  //   children: [],
  // },
  // TabPanel: {
  //   props: {},
  //   children: [],
  // },
  Navbar: {
    props: {
      avatar: 'https://avatars.githubusercontent.com/u/5605?s=48&v=4',
      email: 'email@gmail.com',
      menuItems: [
        {
          active: true,
          href: '#menu-1',
          id: 'menu-1',
          newBadge: false,
          text: 'Menu 1',
        },
      ],
      profileItems: [
        {
          active: false,
          href: '#profile-menu-1',
          id: 'profile-menu-1',
          newBadge: false,
          text: 'Profile Menu 1',
        },
      ],
      username: 'username',
    },
  },

  // Banner,
  // BannerDescription,
  // Breadcrumb,
  // Button,
  // Icon,
  Form: {
    props: {
      action: '/my-handling-form-page',
      method: 'post',
    },
    children: [],
  },
  Input: {
    props: {
      id: 'input-1',
      name: 'input-1',
      className: '',
    },
  },
  // InputGroup,
  // InputLeftAddon,
  // List,
  // ListItem,
  // ProgressBar,
  RadioGroup: {
    props: {
      id: 'radio-group-1',
      name: 'radio-group',
      className: 'flex flex-col mt-2',
    },
    children: [],
  },
  Radio: {
    props: {
      id: 'Option-1',
      value: 'Option 1',
      label: 'Option 1',
      className: 'mt-1',
    },
  },
  // Rating,
  // Select,
  // Separator,
  // Shimmer,
  // Spinner,
  // Stepper,
  // Switch,
  // Text,
  // Textarea,
  // Tooltip,
}

// TODO: add more map
export const UIComponentMap: ComponentMap = {
  div: (props) => (
    <div key={props?.id} className={props.className} {...props} />
  ),
  GridLayout: (props) => <GridLayout key={props?.id} {...props} />,
  Calendar: (props) => <Calendar key={props?.id} {...props} />,
  a: (props) => (
    <a key={props.id} {...props}>
      {props.content}
    </a>
  ),
  hrefContainer: (props) => <a key={props.id} {...props} />,
  ImageHTML: (props) => (
    <div onClick={props.onClick}>
      {/* eslint-disable-next-line */}
      <img
        key={props.id}
        className={props.className}
        src={props.src || 'https://wallpaperaccess.com/full/32048.jpg'}
        width={props.width || 200}
        height={props.height || 200}
      />
    </div>
  ),
  Image: (props) => (
    <Image
      key={props.id}
      className={props.className}
      src={
        props.src ||
        'https://img.freepik.com/free-vector/cute-cat-sleeping-laptop-with-coffee-cartoon-vector-icon-illustration-animal-technology-icon_138676-4475.jpg'
      }
      width={props.width}
      height={props.height}
      alt={props.alt || 'Some Alt'}
    ></Image>
  ),
  Card: (props) => {
    return <Card key={props?.id} {...props} />
  },
  Carousel: (props) => {
    return <Carousel key={props?.id} {...props} />
  },
  span: (props) => (
    <span key={props.id} {...props}>
      {props.content}
    </span>
  ),
  Text: (props) => (
    <Text key={props.id} {...props}>
      {props.content}
    </Text>
  ),
  Icon: (props) => (
    <Icon key={props.id} {...props}>
      {props.content}
    </Icon>
  ),
  Rating: (props) => (
    <Rating key={props.id} {...props}>
      <div>{props.content}</div>
    </Rating>
  ),
  Separator: (props) => <Separator key={props.id} {...props} />,
  Avatar: (props) => <Avatar key={props.id} {...props} />,
  Button: (props) => <Button key={props.id} {...props} />,
  Badge: (props) => (
    <Badge key={props.id} {...props}>
      {props.content}
    </Badge>
  ),
  List: (props) => {
    // TODO: there is a bug where the children are somehow
    // wrapped as array within children array, and the first children
    // becomes a boolean value of `false`. This is a temporary
    // fix to flatten the children array and filter falsy values.
    const flattenChildren = props.children.reduce((acc, child) => {
      if (child instanceof Array) {
        return [...acc, ...child]
      } else if (!!child) {
        return [...acc, child]
      }

      return acc
    }, [])

    const newProps = { ...props, children: flattenChildren }

    return <List key={props.id} {...newProps} />
  },
  ListItem: (props) => (
    <ListItem key={props.id} {...props}>
      {props.content}
    </ListItem>
  ),
  ListItemAsContainer: (props) => <ListItem key={props.id} {...props} />,
  Banner: (props) => (
    <Banner key={props.id} {...props}>
      <BannerDescription>{props.content}</BannerDescription>
    </Banner>
  ),
  ProgressBar: (props) => (
    <ProgressBar key={props.id} {...props}>
      {props.content}
    </ProgressBar>
  ),
  Shimmer: (props) => (
    <Shimmer key={props.id} {...props}>
      {props.content}
    </Shimmer>
  ),
  Spinner: (props) => (
    <Spinner key={props.id} {...props}>
      {props.content}
    </Spinner>
  ),
  Tooltip: (props) => (
    <Tooltip key={props.id} {...props}>
      {props.content}
    </Tooltip>
  ),
  // eslint-disable-next-line
  // Image: (props) => <Image key={props.id} {...props} />,
  Tabs: (props) => (
    <div style={{ width: '100%' }}>
      <Tabs {...props} />
    </div>
  ),
  Tab: (props) => (
    <Tab key={props.id} {...props}>
      {props.content}
    </Tab>
  ),
  TabList: (props) => <TabList key={props.id} {...props} />,
  TabPanels: (props) => <TabPanels key={props.id} {...props} />,
  TabPanel: (props) => <TabPanel key={props.id} {...props} />,
  Breadcrumb: (props) => (
    <Breadcrumb key={props.id} {...props}>
      {props.content}
    </Breadcrumb>
  ),
  Navbar: (props) => (
    <Navbar key={props.id} {...props}>
      {props.content}
    </Navbar>
  ),
  Link: (props) => <Link key={props.id} {...props} />,
  Input: (props) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { children, ...propsWithoutChildren } = props
    return <Input key={props.id} {...propsWithoutChildren} />
  },
  Form: (props) => (
    <form
      key={props.id}
      {...props}
      onSubmit={(event) => {
        event.preventDefault()
      }}
    />
  ),
  RadioGroup: (props) => (
    <RadioGroup key={props.id} {...props} ref={props.ref} />
  ),
  Radio: (props) => (
    <Radio
      key={props.id}
      value={String(props.value) || String(props.id)}
      className="mr-4"
    >
      {props.label}
    </Radio>
  ),
  Collapsible: (props) => {
    return <Collapsible {...props} />
  },
  CollapsibleTrigger: (props) => {
    return <CollapsibleTrigger {...props} />
  },
  CollapsibleIcon: (props) => {
    return <CollapsibleIcon {...props} />
  },
  CollapsibleContent: (props) => {
    return <CollapsibleContent {...props} />
  },
  Expander: (props) => {
    return (
      <Expander maxTextLength={100} {...props} as="p">
        <p className="whitespace-pre-line">{`${props.content}`}</p>
      </Expander>
    )
  },
  Popover: (props) => {
    return <Popover {...props} />
  },
  PopoverTrigger: (props) => {
    return <PopoverTrigger {...props} />
  },
  PopoverContent: (props) => {
    return <PopoverContent {...props} />
  },
  ButtonDropdownMenu: (props) => {
    const [isOpen, setOpen] = React.useState(false)
    const [urlPdf, setUrlPdf] = React.useState('')
    const [namePdf, setNamePdf] = React.useState('')

    return (
      <>
        <Popover placement={props.placement || 'bottom-end'}>
          <PopoverTrigger>
            <Button
              className={props.className}
              variant={props.variant}
              color={props.color}
            >
              {props.triggerText}{' '}
              {props.iconName && <Icon>{props.iconName}</Icon>}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <List type="menu">
              {props.items.map((item) =>
                item.type == 'pdf' ? (
                  <div>
                    <button
                      className="w-full"
                      onClick={() => {
                        setOpen(true)
                        setUrlPdf(item.link)
                        setNamePdf(item.text)
                      }}
                    >
                      <ListItem key={item.id} onClick={item.onClick}>
                        {item.text}
                      </ListItem>
                    </button>
                  </div>
                ) : (
                  <a href={item.link} key={item.id} target={item.target}>
                    <ListItem key={item.id} onClick={item.onClick}>
                      {item.text}
                    </ListItem>
                  </a>
                )
              )}
            </List>
          </PopoverContent>
        </Popover>
        <Dialog isOpen={isOpen} onClose={setOpen} size="lg">
          <DialogOverlay />
          <DialogContent>
            <DialogHeader title={namePdf} variant="compact" />
            <div style={{ padding: 24 }}>
              <div>
                <PdfViewer url={urlPdf} width="auto" />
              </div>
            </div>
            <DialogFooter style={{ gap: 12 }}>
              <DialogCancel
                as={Button}
                color="white"
                onClick={() => setOpen(false)}
              >
                Tutup
              </DialogCancel>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    )
  },
}

// TODO: add more form component
export const FormComponentMap: ComponentMap = {
  // DatePicker: (props) => (
  //   <DatePicker key={props.id} {...props} ref={props.ref} />
  // ),
  // Fileupload: (props) => (
  //   <Fileupload key={props.id} {...props} ref={props.ref} />
  // ),
  Input: (props) => (
    <input
      key={props.id}
      {...props}
      ref={props.ref}
      className={`rounded border-2 p-2 ${props.className}`}
    />
  ),
  // TODO: to adjust fit any addon
  InputAddon: (props) => (
    <InputGroup>
      <InputLeftAddon
        style={{ width: '50px', marginLeft: '8px', marginTop: 8 }}
      >
        <Text color="disabled">{props.leftAddons}</Text>
      </InputLeftAddon>
      <Input
        key={props.id}
        {...props}
        ref={props.ref}
        style={{ paddingLeft: '58px' }}
      />
    </InputGroup>
  ),
  Radio: (props) => (
    <RadioGroup key={props.id} {...props} ref={props.ref}>
      {props.options?.map((option, index) => (
        <Radio
          key={index}
          value={String(option.value) || String(option.id)}
          className="mr-4"
        >
          {option.label}
        </Radio>
      ))}
    </RadioGroup>
  ),
  Select: (props) => (
    <Select key={props.id} {...props} ref={props.ref}>
      {props.options?.map((option, index) => (
        <option key={index} value={option.value || option.id}>
          {option.label}
        </option>
      ))}
    </Select>
  ),
  Stepper: (props) => <Stepper key={props.id} {...props} ref={props.ref} />,
  Switch: (props) => (
    <Switch
      key={props.id}
      {...props}
      onCheckedChange={props.onChange}
      ref={props.ref}
    />
  ),
  Textarea: (props) => <Textarea key={props.id} {...props} ref={props.ref} />,
}

export const formComponentMapToType = {
  string: ['Input', 'Textarea', 'DatePicker'],
  number: ['Input', 'Textarea'],
  boolean: ['Radio', 'Select', 'Switch'],
  date: ['DatePicker'],
  file: ['Fileupload'],
  array: ['Select', 'Checkbox', 'Radio'],
  object: ['Select', 'Checkbox', 'Radio'],
}

export default FormComponentMap
